import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-video.css';
import 'lightgallery/css/lg-transitions.css';

setTimeout(async () => {
  const lightGalleryModule = await import('lightgallery');
  const lgVideoModule = await import('lightgallery/plugins/video');
  const lgZoomModule = await import('lightgallery/plugins/zoom');
  const lgThumbnailModule = await import('lightgallery/plugins/thumbnail');

  const lightGallery = lightGalleryModule.default;
  const lgVideo = lgVideoModule.default;
  const lgZoom = lgZoomModule.default;
  const lgThumbnail = lgThumbnailModule.default;

  setLg();
  setLgVideoAfterClick();

  document.addEventListener('swiper-reviews-updated', setLgVideoAfterClick);
  document.addEventListener('cargo-tracking-lot-updated', setLg);

  function setLg() {
    document.querySelectorAll('[data-lg-gallery-thumbnail]')?.forEach((el) => {
      const gallery = lightGallery(
        el.querySelector('[data-lg-gallery-thumbnail-wrap]'),
        {
          licenseKey: theme.credendionals.LIGHTGALLERY_LICENSE_KEY,
          plugins: [lgZoom, lgThumbnail],
          thumbnail: true,
          zoom: true,
          actualSize: false,
          showZoomInOutIcons: true,
          mobileSettings: { showCloseIcon: true, download: true },
        },
      );
      el.querySelectorAll('[data-lg-gallery-thumbnail-start]').forEach(
        (btn, index) => {
          btn.addEventListener('click', () => {
            gallery.openGallery(index);
          });
        },
      );
      el.querySelector('[data-lg-gallery-thumbnail-overlay]')?.remove();
    });
  }

  function setLgVideoAfterClick() {
    document.querySelectorAll('[data-lg-video-after-click]')?.forEach((el) => {
      if (el.lightGallery) {
        return false;
      }
      el.lightGallery = lightGallery(el, {
        licenseKey: theme.credendionals.LIGHTGALLERY_LICENSE_KEY,
        selector: 'this',
        download: false,
        plugins: [lgVideo],
      });
      return true;
    });
  }
}, 2000);
